import { Flex, FlexProps, Heading, Text } from '@chakra-ui/react';
import { RBaseCardGradient } from 'components';
import { IconEmptySet } from 'icons';

type Props = FlexProps & {
  title?: string;
  message?: string;
};

export const EmptyState = ({ title, message, ...rest }: Props) => {
  return (
    <Flex
      w="full"
      flexDirection="column"
      align="center"
      justify="center"
      position="relative"
      aspectRatio={6 / 2}
      {...rest}
    >
      <IconEmptySet />
      <Heading
        fontSize="18px"
        textTransform="uppercase"
        lineHeight="24px"
        mt={4}
      >
        {title}
      </Heading>
      <Text fontSize="14px" opacity={0.6} lineHeight="18px">
        {message}
      </Text>

      <RBaseCardGradient
        variant="gradientToTransparent"
        gradientColor="orange"
        hasInsetBorder
      />
    </Flex>
  );
};
