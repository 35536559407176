import { Flex } from '@chakra-ui/react';

import Link from 'next/link';
import routes from 'routes';
import { H2 } from './Blocks/H2';
import Image from 'next/image';
import NewItemsImage from '../../public/images/home/mclaren_promo.png';
import { Title } from './Blocks/Title';

export const NewItemsPanel = () => {
  return (
    <Flex
      w="100%"
      h="100%"
      bg="rgba(0, 0, 0, 0.4)"
      as={Link}
      href={routes.marketplace([''])}
      role="group"
    >
      <Flex
        w="100%"
        h="100%"
        position="relative"
        bgGradient="linear(to-t, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
        _hover={{
          bgGradient:
            'linear(to-t, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
        }}
      >
        {/* HEADER */}
        <Flex position="absolute" top={{ base: 4, md: 4 }} left={{ base: 4, md: 4 }}>
          <Title maxW="340px">New Items</Title>
        </Flex>

        {/* CONTENT */}
        <Flex w="100%" h="100%" position="relative">
          <Image
            src={NewItemsImage}
            alt="New Items"
            style={{ objectFit: 'contain' }}
            fill
            sizes="(max-width: 768px) 100vw, 50vw"
          />
        </Flex>

        {/* FOOTER */}
        <Flex position="absolute" bottom={{ base: 4, md: 4 }} left={{ base: 4, md: 4 }}>
          <H2 size="xs">Buy cars, liveries & accessories.</H2>
        </Flex>
        <BottomBarLine />
      </Flex>
    </Flex>
  );
};

export const BottomBarLine = () => {
  return (
    <Flex
      w="calc(100% - 8px)"
      right={0}
      left={0}
      m="auto"
      h="6px"
      borderBottom="2px solid"
      borderX="2px solid"
      borderColor="whiteAlpha.300"
      position="absolute"
      bottom={1}
      transition="all ease-in-out 0.3s"
      _groupHover={{
        borderColor: 'whiteAlpha.600',
      }}
    />
  );
};
