import {
  Flex,
  Heading,
  IconButton,
  Skeleton,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';

import { Title } from './Blocks/Title';
import { IconChevron } from 'icons';
import { ActivityItem } from 'components/UserActivity/ActivityItem';
import useUserActivity from 'hooks/useUserActivity';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { EmptyState } from 'components/Hub/EmptyState';
import { WalletDrawer } from 'components/Wallet/WalletDrawer';

export const RecentActivity = ({ showButton }: { showButton?: boolean }) => {
  const { data: activities, isLoading, isRefetching } = useUserActivity({});

  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    queryClient.invalidateQueries(['userActivity']);
  }, []);

  return (
    <Flex w="100%" h="100%" bg="rgba(0, 0, 0, 0.4)" role="group">
      <Flex
        w="100%"
        h="100%"
        position="relative"
        bgGradient="linear(to-t, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 50%)"
      >
        {/* CONTENT */}
        <Stack
          w="full"
          gap={2}
          px={4}
          py={2}
          overflowY="scroll"
          className="red-scrollbar"
        >
          <Flex w="100%" justify="space-between">
            <Title maxW="340px">Recent Activity</Title>
            {showButton && (
              <IconButton
                onClick={onOpen}
                size="xs"
                variant="secondary"
                aria-label="Next"
                px={4}
                icon={<IconChevron />}
                zIndex={10}
              />
            )}
          </Flex>
          {(isLoading || isRefetching) && <Skeleton w="full" h="64px" />}

          {!isLoading &&
            !isRefetching &&
            activities?.getUserActivity.map((item, index) => (
              <ActivityItem
                key={index}
                message={item.message || ''}
                timestamp={item.timestamp}
                value={item.value}
                action={item.action}
                currency={item.currency || ''}
              />
            ))}

          {!isLoading &&
            !isRefetching &&
            activities?.getUserActivity.length === 0 && (
              <EmptyState
                title="No recent activity"
                message="No recent activity, come back later and check."
                textAlign="center"
                mt="2rem"
              />
            )}
        </Stack>
      </Flex>
      <WalletDrawer isOpen={isOpen} onClose={onClose} defaultIndex={2} />
    </Flex>
  );
};
