import { Flex } from '@chakra-ui/react';

import Link from 'next/link';
import routes from 'routes';
import { H2 } from './Blocks/H2';
import Image from 'next/image';
import GettingStartedImage from '../../public/images/home/getting_started.png';
import { Title } from './Blocks/Title';
import { useUser } from 'context';
import { getRandomAidrianaImg } from 'components/Aidriana/Image';
import { useUserSettings } from 'hooks';

export const GettingStarted = () => {
  const { avatarFullbodyImgSrc } = useUser();
  const { getUserSettings } = useUserSettings();

  return (
    <Flex
      w="100%"
      h="100%"
      bg="rgba(0, 0, 0, 0.4)"
      as={Link}
      href={routes.avatar()}
      role="group"
    >
      <Flex
        w="100%"
        h="100%"
        position="relative"
        bgGradient="linear(to-t, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
        _hover={{
          bgGradient:
            'linear(to-t, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
        }}
      >
        {/* HEADER */}
        <Flex position="absolute" top={{ base: 4, md: 4 }} left={{ base: 4, md: 4 }}>
          <Title maxW="340px">Getting Started</Title>
        </Flex>

        {/* CONTENT */}
        <Flex w="100%" h="100%" position="relative">
          <Image
            src={GettingStartedImage}
            alt="Getting Started"
            style={{ objectFit: 'contain' }}
            fill
            sizes="(max-width: 768px) 100vw, 50vw"
          />
        </Flex>

        <Flex
          w="100%"
          h="calc(100% - 50px)"
          bottom={10}
          m="auto"
          position="absolute"
        >
          <Image
            src={
              getUserSettings.data?.getUser?.avatarId
                ? avatarFullbodyImgSrc
                : getRandomAidrianaImg('full')
            }
            alt="Getting Started"
            style={{ objectFit: 'cover' }}
            fill
            sizes="(max-width: 768px) 100vw, 50vw"
          />
        </Flex>

        {/* FOOTER */}
        <Flex position="absolute" bottom={{ base: 4, md: 4 }} left={{ base: 4, md: 4 }}>
          <H2 maxW="340px" size="xs">
            Edit your avatar.
          </H2>
        </Flex>
        <BottomBarLine />
      </Flex>
    </Flex>
  );
};

export const BottomBarLine = () => {
  return (
    <Flex
      w="calc(100% - 8px)"
      right={0}
      left={0}
      m="auto"
      h="6px"
      borderBottom="2px solid"
      borderX="2px solid"
      borderColor="whiteAlpha.300"
      position="absolute"
      bottom={1}
      transition="all ease-in-out 0.3s"
      _groupHover={{
        borderColor: 'whiteAlpha.600',
      }}
    />
  );
};
