import { useQuery } from '@tanstack/react-query';
import { Status } from 'api/generated/graphql';

import { useGraphQLClient } from 'hooks';

const useGetFeaturedRaces = () => {
  const { graphQLClient, graphql, hasToken } = useGraphQLClient();
  const getFeaturedRacesFn = graphql(`
    query GetFeaturedRace($filter: RacesFilter) {
      getRaces(filter: $filter) {
        id
        track {
          id
          trackName
          codeName
          frontendName
          has2D
          has3D
        }
        prizePool
        startTime
        status
        tier {
          name
          rank
        }
      }
    }
  `);

  return useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['featuredRace'],
    queryFn: async () => {
      return await graphQLClient.request(getFeaturedRacesFn, {
        filter: {
          status: [Status.Open],
          limit: 1,
        },
      });
    },
  });
};

export default useGetFeaturedRaces;
