import { Heading, HeadingProps } from '@chakra-ui/react';

type TitleProps = HeadingProps & {
  children: React.ReactNode;
};
export const Title = ({ children, ...props }: TitleProps) => {
  return (
    <Heading
      as="h1"
      fontFamily="body"
      textColor="whiteAlpha.600"
      size={['sm', 'sm']}
      textTransform="uppercase"
      pt={[1, 0]}
      {...props}
    >
      {children}
    </Heading>
  );
};
