import { Heading, HeadingProps } from '@chakra-ui/react';

type H2Props = HeadingProps & {
  children: React.ReactNode;
};
export const H2 = ({ children, ...props }: H2Props) => {
  return (
    <Heading
      as="h2"
      size={['sm', 'sm']}
      textTransform="uppercase"
      pt={[1, 0]}
      {...props}
    >
      {children}
    </Heading>
  );
};
